import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PageHeader from '../components/PageHeader';
import PostSection from '../components/PostSection';
import Layout from '../components/Layout';

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
// export const byCategory = (posts, title, contentType) => {
//     const isCategory = contentType === 'postCategories';
//     const byCategory = post =>
//         post.categories &&
//         post.categories.filter(cat => cat.category === title).length;
//     return isCategory ? posts.filter(byCategory) : posts;
// };

// Export Template for use in CMS preview
const BlogIndexTemplate = ({
  Title,
  Sub_Title,
  Featured_Image,
  featuredImageOffsetX,
  featuredImageOffsetY,
  News_Post = []
  // contentType
}) => (
  <main className="Blog">
    <PageHeader
      className="blog_padding"
      title={Title}
      subtitle={Sub_Title}
      backgroundImage={Featured_Image}
      imageOffsetX={featuredImageOffsetX}
      imageOffsetY={featuredImageOffsetY}
    />

    {!!News_Post.length && (
      <section className="section">
        <div className="container">
          <div />
          <PostSection posts={News_Post} />
        </div>
      </section>
    )}
  </main>
);
// Export Default BlogIndex for front-end
const BlogIndex = () => {
  const data = useStaticQuery(pageQuery);
  const page = data.directus.News_Page;
  const { News_Item } = data.directus;
  return (
    <Layout meta={page.Default_Seo || false} title={page.Title || false}>
      <BlogIndexTemplate {...page} News_Post={News_Item} />
    </Layout>
  );
};

const pageQuery = graphql`
  query articlePage {
    directus {
      News_Page {
        Title
        Sub_Title
        Featured_Image {
          id
        }
        featuredImageOffsetX
        featuredImageOffsetY
        Default_Seo {
          Meta_Title
          Meta_Description
          Share_Image {
            id
          }
        }
      }
      News_Item {
        Default_Seo {
          Meta_Title
          Meta_Description
          Share_Image {
            id
          }
        }
        Title
        Excerpt
        Content
        Feature_Image {
          id
        }
        id
        Post_Date
      }
    }
  }
`;

export default BlogIndex;
