/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import {
  Image,
  Card,
  // Image,
  Text,
  // Badge,
  Button,
  Group,
  useMantineTheme
} from '@mantine/core';

// import Image from './Image';
import './PostCard.css';

const PostCard = ({
  id,
  Feature_Image,
  Title,
  Excerpt,
  Post_Date,
  // className = '',
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const handleClick = postId => {
    navigate(`/single-post?id=${postId}`);
  };
  const theme = useMantineTheme();

  const pd = Post_Date
    ? dayjs(Post_Date).format('dddd, MMMM D YYYY')
    : dayjs().format('dddd, MMMM D YYYY');
  // const secondaryColor =
  //   theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7];
  return (
    <Card shadow="sm" padding="lg" className="postCard_back_body">
      <Card.Section>
        <Group
          className="postCard_back"
          style={{
            // backgroundColor: '--var(nav_background)',
            marginBottom: 0,
            padding: 4,
            paddingLeft: 20
          }}
        >
          <Text size="xs" weight={500}>
            {pd}
          </Text>
        </Group>
        <Group
          style={{
            width: 400,
            height: 250,
            marginBottom: 5
          }}
        >
          {Feature_Image && (
            <div style={{ width: '400px', height: '250px' }}>
              <Image
                width={400}
                height={250}
                src={`https://sanders.clockworkbee.co.uk/assets/${Feature_Image.id}`}
                alt="e"
              />
            </div>
          )}
        </Group>
      </Card.Section>
      <Group
        position="apart"
        style={{
          marginBottom: 5,
          marginTop: theme.spacing.sm
        }}
      >
        <Text weight={500}>{Title}</Text>
      </Group>
      <Group
        position="apart"
        style={{
          wordBreak: 'break-word',
          marginBottom: 5,
          marginTop: theme.spacing.sm
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: Excerpt }} />
      </Group>

      <Button
        onClick={() => {
          handleClick(id);
        }}
        variant="dark"
        fullWidth
        className="MantineButton"
        style={{ marginTop: 14 }}
      >
        Read
      </Button>
    </Card>
    // <div
    //   className={`PostCard ${className}`}
    //   onClick={() => {
    //     handleClick(id);
    //   }}
    // >
    //   {postDate && (
    //     <div
    //       style={{
    //         padding: 4,
    //         marginLeft: 8,
    //         color: '#606a2b'
    //       }}
    //     >
    //       {dayjs(postDate).format('dddd, MMMM D YYYY')}
    //     </div>
    //   )}
    //   {featuredImage && (
    //     <div className="PostCard--Image relative">
    //       <Image background asset={featuredImage} alt={title} />
    //     </div>
    //   )}
    //   <div className="PostCard--Content">
    //     {title && <h3 className="PostCard--Title">{title}</h3>}
    //     {excerpt && (
    //       <div className="PostCard--Excerpt">
    //         <RichText content={excerpt.json} />
    //       </div>
    //     )}
    //   </div>
    // </div>
  );
};

export default PostCard;
