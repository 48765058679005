/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
import React, { useState } from 'react';

import PostCard from './PostCard';
import './PostSection.css';

const PostSection = props => {
  const [limit, setLimit] = useState(props.limit);
  const increaseLimit = () => {
    const x = limit + props.perPageLimit;
    setLimit(x);
  };

  const { posts, title, showLoadMore, loadMoreTitle } = props;
  const visiblePosts = posts.slice(0, limit || posts.length);
  return (
    <div className="PostSection">
      {title && <h2 className="PostSection--Title">{title}</h2>}
      {!!visiblePosts.length && (
        <div className="PostSection--Grid">
          {visiblePosts.map((post, index) => (
            <PostCard key={post.title + index} {...post} posts={posts} />
          ))}
        </div>
      )}
      {showLoadMore && visiblePosts.length < posts.length && (
        <div className="taCenter">
          <button className="button" onClick={increaseLimit}>
            {loadMoreTitle}
          </button>
        </div>
      )}
    </div>
  );
};

PostSection.defaultProps = {
  posts: [],
  title: '',
  limit: 12,
  showLoadMore: true,
  loadMoreTitle: 'Load More',
  perPageLimit: 12
};

export default PostSection;
